<template>
  <modal :priority="isTopPriority" @close-auth-pop="modalOverlayClicked()">
    <template v-slot:body>
      <form method="post" name="form_chk" id="authForm" ref="form">
        <!-- 요청모드 (필수 데이터) -->
        <input type="hidden" name="m" value="checkplusSerivce" />
        <!-- 업체정보 암호화 데이터 -->
        <input type="hidden" name="EncodeData" :value="GET_ENC_DATA.encData" />
      </form>
    </template>
  </modal>
</template>
<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import Modal from "./ModalAuth.vue";
export default {
  props: {
    popupSet: {
      isTopPriority: {
        type: Boolean,
      },
    },
  },

  data() {
    return {
      isTopPriority: this.popupSet.isTopPriority,
    };
  },
  watch: {},
  mounted() {
    if (this.GET_ENC_DATA !== undefined) {
      this.fnPopup();
    } else {
      this.$emit("identify-result", null);
      this.SET_AUTH_POPUP(false);
    }
  },
  computed: {
    ...mapState("basic", ["checkAuthPopup"]),
    ...mapGetters("join", ["GET_ENC_DATA", "GET_IDENTIFY"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_AUTH_POPUP"]),
    ...mapActions("join", ["IDENTIFY_SUCCESS"]),
    fnPopup() {
      let nero = window.open(
        "",
        "popupChk",
        "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
      );
      document.form_chk.action =
        "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
      document.form_chk.target = "popupChk";
      document.form_chk.submit();
      const doTimer = setInterval(() => {
        if (nero === null || nero.closed) {
          nero = null;
          clearInterval(doTimer);
          this.modalOverlayClicked("isDetect");
        }
      }, 1000);
    },
    async modalOverlayClicked(dataValue) {
      /** API 실행하고 => 리스폰스 받기 */
      /** getter 보내기 */
      if (dataValue !== undefined) {
        //console.log("X버튼으로 끔");
      } else {
        window.open("close", "popupChk", "");
        //console.log("오버레이로 끔");
      }

      
      try {
        await this.IDENTIFY_SUCCESS(this.GET_ENC_DATA.reqSeq).then(() => {
          //console.log("서버에서 전달받은 본인인증 입력 값", this.GET_IDENTIFY);
          this.$emit("identify-result", this.GET_IDENTIFY);
          this.SET_AUTH_POPUP(false);
        });
      } catch (error) {
        //console.log("본인인증 성공 시 데이터보내는 API Error: ", error);
        this.$emit("identify-result", null);
        this.SET_AUTH_POPUP(false);
      }
    },
  },
  components: {
    Modal,
  },
  created() {
    //console.log("팝업 오픈하면서 다시 리로드한 enc-data", this.GET_ENC_DATA);
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped></style>

<template>
  <transition name="modal">
    <div
      class="modal-mask"
      @click="overlayClick($event)"
      :class="{ isTopPriority: priority }"
    >
      <div class="modal-wrapper transparent">
        <div class="modal-container" @click.stop>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    priority: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },

  computed: {},
  methods: {
    overlayClick(event) {
      const target = event.target;
      //console.log("타겟", target);
      //console.log("커런트", event.currentTarget);
      this.$emit("close-auth-pop");
    }
  },
  mounted() {},
  created() {}
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="pr-2 pl-2">
    <v-container fluid class="pt-10 pr-0 pl-0 ma-0">
      <v-row no-gutters justify="center" class="pb-10">
        <!-- 타이틀 -->
        <v-col
          cols="12"
          class="pt-4 pb-8 reform_primary--text text-center text-h5 font-weight-bold"
          >{{ directiveInfo.title }}
        </v-col>
        <v-col
          cols="12"
          v-html="directiveInfo.content"
          class="text-center font-weight-medium pb-10"
        >
        </v-col>
        <!-- 타이틀끝 -->
        <!-- 테이블 -->
        <v-col cols="12" md="8" lg="8" xl="8">
          <v-container fluid class="pa-0 custom-table">
            <!-- 이름 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>이름</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 text-h6 custom-table-cell background-cell"
              >
                <div>
                  {{ form.name }}
                </div>
              </v-col>
            </v-row>
            <!-- 생년월일 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>생년월일</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 text-h6 custom-table-cell background-cell"
              >
                <div>
                  {{ form.birth }}
                </div>
              </v-col>
            </v-row>
            <!-- 성별 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>성별</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pl-5 pr-5 text-h6 custom-table-cell background-cell"
              >
                <v-radio-group v-model="form.gender" row readonly>
                  <v-radio
                    label="남성"
                    value="1"
                    color="reform_primary"
                    class="font-weight-bold"
                  ></v-radio>
                  <v-radio
                    label="여성"
                    value="0"
                    color="reform_primary"
                    class="font-weight-bold"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <!-- 아이디 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>아이디</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 text-h6 custom-table-cell background-cell"
              >
                <div>
                  {{ form.id }}
                </div>
              </v-col>
            </v-row>
            <!-- 휴대폰 번호 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>휴대폰 번호</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 text-h6 custom-table-cell background-cell"
              >
                <v-row
                  align="center"
                  justify="space-between"
                  no-gutters
                  v-if="!isSecondId"
                >
                  <v-col md="6">
                    <v-text-field
                      placeholder="휴대폰 번호를 입력해주세요."
                      outlined
                      dense
                      v-model="form.phone.value"
                      maxlength="8"
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      class="ml-5 text-h6"
                      width="100px"
                      @click="changePhoneNum()"
                      elevation="0"
                      color="reform_orange"
                      dark
                      rounded
                    >
                      변경
                    </v-btn>
                  </v-col>
                </v-row>
                <div v-else>
                  {{ form.phone.value }}
                </div>
              </v-col>
            </v-row>
            <!-- 이메일 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>이메일</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 text-h6 custom-table-cell background-cell"
              >
                <v-row
                  align="center"
                  justify="start"
                  no-gutters
                  v-if="!isSecondId"
                >
                  <v-col cols="12">
                    <v-text-field
                      placeholder="정확한 형식의 이메일을 입력해주세요."
                      outlined
                      dense
                      v-model="form.email.value"
                      hide-details
                      @keyup="checkEmail()"
                      @blur="checkEmail()"
                      @keyup.enter="focusControl()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <div class="warn-message" v-if="form.email.hasError">
                      {{ form.email.errorTxt }}
                    </div>
                  </v-col>
                </v-row>
                <div v-else>
                  {{ form.email.value }}
                </div>
              </v-col>
            </v-row>
            <!-- 병원명 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>병원명</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 text-h6 custom-table-cell background-cell"
              >
                <v-row
                  align="center"
                  justify="start"
                  no-gutters
                  v-if="!isSecondId"
                >
                  <v-col cols="12">
                    <v-textarea
                      no-resize
                      auto-grow
                      rows="1"
                      outlined
                      hide-details
                      dense
                      placeholder="병원명을 입력해주세요"
                      v-model="form.hospital.value"
                      @keyup="checkHospital()"
                      @blur="checkHospital()"
                      @keyup.enter="focusControl()"
                      maxlength="40"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <div class="warn-message" v-if="form.hospital.hasError">
                      {{ form.hospital.errorTxt }}
                    </div>
                  </v-col>
                </v-row>
                <div v-else>
                  {{ form.hospital.value }}
                </div>
              </v-col>
            </v-row>
            <!-- 병원 전화번호 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>병원전화번호</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 text-h6 custom-table-cell background-cell"
              >
                <v-row
                  align="center"
                  justify="start"
                  no-gutters
                  v-if="!isSecondId"
                >
                  <v-col cols="12">
                    <v-text-field
                      placeholder="병원전화번호를 입력해주세요."
                      outlined
                      dense
                      v-model="form.hospitalTelNo.value"
                      hide-details
                      @keyup="checkHospitalTelNo()"
                      @blur="checkHospitalTelNo()"
                      maxlength="40"
                      @keyup.enter="focusControl()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="form.hospitalTelNo.hasError">
                    <div class="warn-message">
                      {{ form.hospitalTelNo.errorTxt }}
                    </div>
                  </v-col>
                </v-row>
                <div v-else>
                  <span
                    class=""
                    v-text="form.hospitalTelNo.value"
                    v-if="this.GET_BASIC.telNo !== null"
                  ></span>
                  <span class="" v-text="'등록되지 않음'" v-else></span>
                </div>
              </v-col>
            </v-row>
            <!-- 근무형태 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>근무형태</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 text-h6 custom-table-cell background-cell"
              >
                <v-row
                  align="center"
                  justify="start"
                  no-gutters
                  v-if="!isSecondId"
                >
                  <v-col cols="12">
                    <v-select
                      :items="form.employType.options"
                      v-model="form.employType.value"
                      ref="employType"
                      @input="isEmployType()"
                      hide-details
                      outlined
                      dense
                      color="reform_primary"
                      item-color="reform_primary"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" v-if="form.employType.hasError">
                    <p class="warn-message">
                      {{ form.employType.errorTxt }}
                    </p>
                  </v-col>
                </v-row>
                <div v-else>
                  {{ form.employType.value }}
                </div>
              </v-col>
            </v-row>
            <!-- 고용형태 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>고용형태</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pl-5 pr-5 text-h6 custom-table-cell background-cell"
              >
                <v-radio-group v-model="form.delegate.value" row>
                  <v-radio
                    label="대표의사"
                    value="0"
                    color="reform_primary"
                    class="font-weight-bold"
                    @click="checkDelegate(0)"
                  ></v-radio>
                  <v-radio
                    label="근무의사"
                    value="1"
                    color="reform_primary"
                    class="font-weight-bold"
                    @click="checkDelegate(1)"
                  ></v-radio>
                  <v-radio
                    label="기타"
                    value="2"
                    color="reform_primary"
                    class="font-weight-bold"
                    @click="checkDelegate(2)"
                  ></v-radio>
                </v-radio-group>
                <div class="warn-message" v-if="form.delegate.hasError">
                  {{ form.delegate.errorTxt }}
                </div>
              </v-col>
            </v-row>
            <!-- 의사면허번호 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>의사면허번호</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 text-h6 custom-table-cell background-cell"
              >
                <div>
                  {{ form.doctorLicense }}
                </div>
              </v-col>
            </v-row>
            <!-- 의사면허등록 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>의사면허등록</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 text-h6 custom-table-cell background-cell"
              >
                <v-text-field
                  hide-details
                  outlined
                  dense
                  v-model="form.attachment[0].filename"
                  readonly
                  @click="download(0)"
                ></v-text-field>
                <v-btn
                  v-if="!isSecondId"
                  class="ml-5 text-h6"
                  width="100px"
                  elevation="0"
                  color="reform_orange"
                  dark
                  rounded
                  @click="attachmentFile(0)"
                >
                  파일첨부
                </v-btn>
                <p
                  class="warn-message need"
                  v-html="computedLine(form.attachment[0].warn)"
                  v-if="form.attachment[0].filename === ''"
                ></p>
                <div class="drLicense">
                  <v-file-input
                    style="display: none"
                    class="hidden file-upload"
                    id="fileupload"
                    @change="onFileChange(0, $event)"
                    @click.stop
                  >
                  </v-file-input>
                </div>
              </v-col>
            </v-row>
            <!-- 병원주소 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>병원주소</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 text-h6 custom-table-cell background-cell"
              >
                <v-text-field
                  hide-details
                  outlined
                  dense
                  v-model="form.address.buildingname.value"
                  placeholder="병원주소를 입력해주세요"
                  readonly
                ></v-text-field>
                <v-btn
                  v-if="!isSecondId"
                  class="ml-5 text-h6"
                  width="100px"
                  elevation="0"
                  color="reform_orange"
                  dark
                  rounded
                  @click="searchAddress()"
                >
                  주소검색
                </v-btn>
              </v-col>
            </v-row>
            <!-- 상세주소 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>상세주소</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 text-h6 custom-table-cell background-cell"
              >
                <v-text-field
                  hide-details
                  outlined
                  dense
                  v-model="form.zipCodeDetail.value"
                  @keyup="checkZipCodeDetail()"
                  @blur="checkZipCodeDetail()"
                  @keyup.enter="focusControl()"
                  placeholder="상세주소를 입력해주세요"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- 지도 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>지도</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 text-h6 custom-table-cell background-cell"
                ref="mapCol"
                v-resize="onResize"
              >
                <naver-map
                  v-if="form.address.locationPoint.posX !== 1 && loadMap"
                  :posX="form.address.locationPoint.posX"
                  :posY="form.address.locationPoint.posY"
                  :address="form.address.buildingname.value"
                  :propsWidth="mapWidth"
                  :propsHeight="400"
                ></naver-map>
              </v-col>
            </v-row>
            <!-- 마케팅 동의 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>마케팅 동의</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 text-h6 custom-table-cell background-cell"
              >
                <v-row class="pl-2">
                  <v-checkbox
                    :readonly="isSecondId"
                    @click="checkMarketing(index)"
                    v-for="(mr, index) in form.agreement"
                    :key="index"
                    class="pr-2"
                    v-model="form.agreement[index].flag"
                    :label="mr.title"
                    color="reform_primary"
                  />
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <!-- 테이블끝 -->
      </v-row>
      <!-- 아래버튼 -->
      <!-- case1 -->
      <v-row justify="center" v-if="form.delegate.value === '0'">
        <!-- <v-btn
          class="mr-5"
          x-large
          rounded
          width="150px"
          elevation="0"
          color="reform_primary"
          dark
          @click="goPrev()"
        >
          이전단계
        </v-btn> -->
        <v-btn
          x-large
          rounded
          width="150px"
          elevation="0"
          @click="next()"
          :class="{
            active:
              form.phone.flag &&
              form.email.flag &&
              form.hospital.flag &&
              form.employType.flag &&
              form.address.buildingname.flag &&
              form.attachment[1].flag,
          }"
        >
          정보저장
        </v-btn>
      </v-row>
      <!-- /case1 -->
      <!-- case2 -->
      <v-row
        justify="center"
        v-else-if="form.delegate.value !== '0' && form.delegate.value !== '2'"
      >
        <!-- <v-btn
          class="mr-5"
          x-large
          rounded
          width="150px"
          elevation="0"
          color="reform_primary"
          dark
          @click="goPrev()"
        >
          이전단계
        </v-btn> -->
        <v-btn
          x-large
          rounded
          width="150px"
          elevation="0"
          @click="next()"
          :class="{
            active:
              form.phone.flag &&
              form.email.flag &&
              form.hospital.flag &&
              form.employType.flag &&
              form.address.buildingname.flag,
          }"
        >
          정보저장
        </v-btn>
      </v-row>
      <!-- /case2 -->
      <!-- case3 -->
      <v-row justify="center" v-else-if="form.delegate.value === '2'">
        <!-- <v-btn
          class="mr-5"
          x-large
          rounded
          width="150px"
          elevation="0"
          color="reform_primary"
          dark
          @click="goPrev()"
        >
          이전단계
        </v-btn> -->
        <v-btn
          x-large
          rounded
          width="150px"
          elevation="0"
          @click="next()"
          :class="{
            active:
              form.phone.flag &&
              form.email.flag &&
              form.hospital.flag &&
              form.employType.flag &&
              form.delegateEtc.flag &&
              form.address.buildingname.flag,
          }"
        >
          정보저장
        </v-btn>
      </v-row>
      <!-- /case3 -->
      <!-- 아래버튼끝 -->
      <button id="focusControl"></button>
    </v-container>
    <auth-popup
      v-if="checkAuthPopup"
      :popupSet="popupAuthSet"
      @identify-result="receiveIdentifyResult"
    ></auth-popup>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
    <address-popup
      @responseAddress="getAddress($event)"
      v-if="checkAddressPopup"
      :popupSetAddress="popupSetAddress"
    />
  </div>
</template>

<script>
import AuthPopup from "@/components/modal/AuthPopup";
import DefaultPopup from "@/components/modal/DefaultPopup";
import AddressPopup from "@/components/modal/AddressPopup";
import NaverMap from "@/components/NaverMapModify";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    AuthPopup,
    DefaultPopup,
    AddressPopup,
    NaverMap,
  },
  data() {
    return {
      mapWidth: 0,
      loadMap: true,
      headerOption: {
        navOrder: 1,
      },
      checkbox_selected: [],
      directiveInfo: {
        title: "회원정보 수정",
        isTitleNewLine: false,
        content:
          "'기본정보'는 진료의뢰서 발송에 필수적인 내용입니다.\n 변경 후 수정하기 버튼을 누르면 수정완료됩니다.",
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
      },
      form: {
        doctorLicense: "",
        id: "",
        birth: "",
        gender: "",
        name: "",
        phone: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*-을 제외한 숫자만을 입력해주세요",
        },
        email: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*이메일 형식을 확인해주세요",
        },
        hospital: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*병원명을 입력해주세요",
        },
        hospitalTelNo: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*병원전화번호를 입력해주세요",
        },
        zipCodeDetail: {
          value: "",
          flag: false,
        },
        employType: {
          options: ["의원", "병원", "종합병원", "상급종합병원"],
          value: "" || "항목 선택",
          flag: false,
          hasError: false,
          errorTxt: "근무형태를 선택해주세요",
        },
        delegate: {
          value: null,
          flag: false,
          hasError: false,
          errorTxt: "고용형태를 선택해주세요.",
        },
        delegateEtc: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "기타내용을 입력해주세요",
        },
        buisnessLicense: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "사업자번호를 입력해주세요",
        },
        address: {
          buildingname: {
            value: "",
            flag: false,
            hasError: false,
            errorTxt: "주소를 입력해주세요",
          },
          zonecode: {
            value: "",
          },
          bname: {
            value: "",
          },
          aim: {
            value: "",
          },
          locationPoint: {
            posX: 1,
            posY: 1,
          },
        },
        attachment: [
          {
            isDeleted: null,
            flag: false,
            title: "의사면허 첨부파일",
            source: null,
            url: "",
            filename: "",
            warn: "의사면허등록 파일은 필수등록사항 입니다",
          },
          {
            isDeleted: null,
            flag: false,
            title: "사업자번호 첨부파일",
            source: null,
            url: "",
            filename: "",
            warn: "사업자등록 파일은 필수등록사항 입니다",
          },
        ],
        agreement: [
          {
            flag: false,
            title: "Email 수신동의",
          },
          {
            flag: false,
            title: "SMS 수신동의",
          },
        ],
        licAttchId: null,
        saupjaAttchId: null,
        mktCollAgrYn: false,
      },
      isSecondId: false,
      popupSet: {},
      popupAuthSet: {},
      popupSetAddress: {},
    };
  },
  created() {
    this.getIdType();
    this.getMemberInfo();
  },
  mounted() {
    this.onResize();
  },
  computed: {
    ...mapState("basic", [
      "checkPopup",
      "checkAddressPopup",
      "checkAuthPopup",
      "isChildLoading",
    ]),
    ...mapGetters("join", ["GET_BASIC", "GET_IDENTIFY"]),
    computedLine() {
      return (text) => {
        if (text.indexOf("\n") !== -1) {
          return text.split("\n").join("<br />");
        } else {
          return text;
        }
      };
    },
    genetateHyphen() {
      return (numData, isBirth) => {
        const number = numData.toString().replace(/[^0-9]/g, "");
        let generatedNum = "";
        if (isBirth) {
          if (number.length < 5) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4, 2);
            generatedNum += "-";
            generatedNum += number.substr(6);
          }
          this.form.birth = generatedNum;
        } else {
          if (number.length < 4) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 3);
            generatedNum += "-";
            generatedNum += number.substr(6);
          } else {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 4);
            generatedNum += "-";
            generatedNum += number.substr(7);
          }
          this.form.phone.value = generatedNum;
        }
        return generatedNum;
      };
    },
  },
  methods: {
    ...mapMutations("basic", [
      "SET_POPUP",
      "SET_ADDRESS_POPUP",
      "SET_AUTH_POPUP",
    ]),
    ...mapMutations("join", ["SET_JOIN"]),
    ...mapActions("join", [
      "FETCH_BASIC",
      "FETCH_ENC_DATA",
      "MODIFY_BASIC",
      "FETCH_BASIC_ADDRESS",
    ]),
    onResize() {
      this.mapWidth = this.$refs.mapCol.clientWidth - 40;
    },
    focusControl() {
      const focusControl = document.getElementById("focusControl");
      //console.log(focusControl);
      focusControl.focus({ preventScroll: true });
    },
    listReaded(order) {
      document.querySelector(".tabs__header").childNodes[order].click();
    },
    initAuthInfo() {
      //console.log("앱헤더에서 부를 것");
      this.$refs.appheader.calledNav("withDraw");
    },
    async callPopWithSidePassword() {
      let pop;
      pop = {
        popType: "CheckPasswordOwn",
        title: "비밀번호 확인",
        content: "현재 비밀번호를 입력해주세요.",
        immediatly: true,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    saupjaFomatter(number) {
      let formatNum = "";
      formatNum = number.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
      return formatNum;
    },
    phoneFomatter(number) {
      //console.log("정보", number);
      //console.log("정보렝", number.length);
      let formatNum = "";
      if (number === null) number = "";
      if (number.length == 11) {
        formatNum = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
      } else if (number.length == 8) {
        formatNum = number.replace(/(\d{4})(\d{4})/, "$1-$2");
      } else {
        //console.log("핵검증", number.indexOf("02"));
        if (number.indexOf("02") == 0) {
          if (number.length === 9) {
            formatNum = number.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
          } else {
            formatNum = number.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          }
        } else {
          formatNum = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
      }
      return formatNum;
    },
    getIdType() {
      if (localStorage.getItem("memType") !== "D") {
        this.isSecondId = true;
      }
    },
    checkMktCollAgr() {
      for (let i = 0; i < this.form.agreement.length; i++) {
        if (this.form.agreement[i].flag) {
          this.form.mktCollAgrYn = true;
        }
      }
      if (!(this.form.agreement[0].flag || this.form.agreement[1].flag)) {
        this.form.mktCollAgrYn = false;
      }
    },
    checkMarketing(order) {
      if (!this.isSecondId) {
        this.checkMktCollAgr();
      }
    },
    async download(order) {
      //console.log("Donwload 누르기");
      if (order === 0) {
        if (this.GET_BASIC.licAttchNm === this.form.attachment[0].filename) {
          window.open(
            "https://www.drrefer.net/api/lic/" + this.GET_BASIC.licAttchId,
            "_blank"
          );
        }
      } else if (order === 1) {
        if (this.GET_BASIC.saupjaAttchNm === this.form.attachment[1].filename) {
          window.open(
            "https://www.drrefer.net/api/saupja/" +
              this.GET_BASIC.saupjaAttchId,
            "_blank"
          );
        }
      }
    },
    deleteAttach(order) {
      //console.log("순번", order);
      this.form.attachment[order].isDeleted = 1;
      this.form.attachment[order].flag = false;
      this.form.attachment[order].source = null;
      this.form.attachment[order].url = "";
      this.form.attachment[order].filename = "";
      if (order === 0) {
        document
          .querySelector(".drLicense")
          .querySelector("input.file-upload").value = "";
      } else if (order === 1) {
        document
          .querySelector(".buisnessLicense")
          .querySelector("input.file-upload").value = "";
      }
    },
    checkCorporateRegistrationNumber(value) {
      var valueMap = value
        .replace(/-/gi, "")
        .split("")
        .map(function (item) {
          return parseInt(item, 10);
        });

      if (valueMap.length === 10) {
        var multiply = new Array(1, 3, 7, 1, 3, 7, 1, 3, 5);
        var checkSum = 0;

        for (var i = 0; i < multiply.length; ++i) {
          checkSum += multiply[i] * valueMap[i];
        }

        checkSum += parseInt((multiply[8] * valueMap[8]) / 10, 10);
        return Math.floor(valueMap[9]) === 10 - (checkSum % 10);
      }

      return false;
    },
    checkBuisnessLicense() {
      this.form.buisnessLicense.flag = false;
      this.form.buisnessLicense.hasError = true;
      const BuisnessLicense = this.form.buisnessLicense.value.replace(
        /\s/gi,
        ""
      );
      if (this.form.buisnessLicense.value.length !== 10) {
        this.form.buisnessLicense.errorTxt =
          "*사업자 등록번호는 하이픈('-')을 제외한 10자리 숫자를 입력해 주세요.";
        this.form.buisnessLicense.flag = false;
        this.form.buisnessLicense.hasError = true;
      } else {
        if (BuisnessLicense === "") {
          this.form.buisnessLicense.hasError = true;
          return false;
        }

        this.form.buisnessLicense.flag = true;
        this.form.buisnessLicense.hasError = false;
      }
    },
    initDelegateEtc() {
      this.form.delegateEtc.flag = false;
      this.form.delegateEtc.hasError = false;
      this.form.delegateEtc.value = "";
    },
    initBuisnessAttachment() {
      this.form.attachment[1].isDeleted = null;
      this.form.attachment[1].flag = false;
      this.form.attachment[1].source = null;
      this.form.attachment[1].url = "";
      this.form.attachment[1].filename = "";
    },
    initBuisnessLicense() {
      this.form.buisnessLicense.value = "";
      this.form.buisnessLicense.flag = false;
      this.form.buisnessLicense.hasError = false;
    },
    checkDelegateEtc() {
      this.form.delegateEtc.flag = false;
      this.form.delegateEtc.hasError = true;
      const DelefateEtc = this.form.delegateEtc.value.replace(/\s/gi, "");
      if (DelefateEtc === "") {
        this.form.delegateEtc.hasError = true;
        return false;
      }
      this.form.delegateEtc.flag = true;
      this.form.delegateEtc.hasError = false;
    },
    onFileChange(order, event) {
      const getFile = event;
      const getFileUrl = event ? URL.createObjectURL(event) : "";
      this.form.attachment[order].source = event;
      /** getFile */
      this.form.attachment[order].url = getFileUrl;
      this.form.attachment[order].filename = event ? getFile.name : "";
      this.form.attachment[order].flag = true;
      this.form.attachment[order].isDeleted = 0;
    },
    attachmentFile(order) {
      let parentNode;
      if (order === 0) {
        let fileUpload = document.getElementById("fileupload");
        if (fileUpload != null) {
          fileUpload.click();
        }
      } else if (order === 1) {
        parentNode = document.querySelector(".buisnessLicense");
        parentNode.querySelector(".file-upload").click();
      }
    },
    checkDelegate(order) {
      this.initDelegateEtc();
      this.initBuisnessLicense();
      this.initBuisnessAttachment();
      this.form.delegate.flag = true;
      this.form.delegate.hasError = false;
      if (order === 0) {
        this.form.buisnessLicense.hasError = true;
      } else if (order === 2) {
        this.form.delegateEtc.hasError = true;
      }
    },
    isEmployType() {
      this.form.employType.flag = true;
      this.form.employType.hasError = false;
    },
    receiveIdentifyResult(userInfo) {
      if (userInfo.birthdate !== null) {
        this.SET_POPUP(true);
        this.popupSet.title = "본인인증 성공";
        this.popupSet.content = "본인인증이 정상적으로 완료되었습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        this.form.phone.value = this.phoneFomatter(userInfo.mobileNo);
        this.form.phone.flag = true;
        this.form.phone.hasError = false;
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "본인인증 실패";
        this.popupSet.content = "본인인증이 완료되지 않았습니다. ";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    async changePhoneNum() {
      try {
        await this.FETCH_ENC_DATA().then(() => {
          this.SET_AUTH_POPUP(true);
          this.popupAuthSet.isTopPriority = true;
          this.popupAuthSet.isTransparent = true;
        });
      } catch (error) {
        console.log("본인인증 시도 전 ENC-DATA를 받는 API Error: ", error);
      }
    },
    checkPhone() {
      this.form.phone.flag = false;
      this.form.phone.hasError = true;
      const phone = this.form.phone.value.replace(/\s/gi, "");
      if (phone === "") {
        this.form.phone.flag = false;
        this.form.phone.hasError = true;
        this.form.phone.errorTxt = "*핸드폰번호를 입력해주세요.";
        return false;
      }
      const isPhone = /^[0-9]{8,11}$/;
      if (!isPhone.test(phone)) {
        this.form.phone.errorTxt = "*-을 제외하고 숫자만 입력해주세요.";
        this.form.phone.flag = false;
        this.form.phone.hasError = true;
        return false;
      }
      this.form.phone.flag = true;
      this.form.phone.hasError = false;
      this.form.phone.errorTxt = "";
      return true;
    },
    async getMemberInfo() {
      try {
        await this.FETCH_BASIC().then(() => {
          //console.log("가져온데이터", this.GET_BASIC);
          /** API 통신해서 받아온 문자열 */
          const fetchedMemberInfo = {
            licNo: this.GET_BASIC.licNo,
            drNm: this.GET_BASIC.drNm,
            birth: this.GET_BASIC.birth,
            sex: this.GET_BASIC.sex,
            drId: this.GET_BASIC.drId,
            hpNo: this.GET_BASIC.hpNo,
            email: this.GET_BASIC.email,
            hospNm: this.GET_BASIC.hospNm,
            telNo: this.phoneFomatter(this.GET_BASIC.telNo),
            postNo: this.GET_BASIC.postNo,
            addr: this.GET_BASIC.addr,
            addr2: this.GET_BASIC.addr2,
            addrDtl: this.GET_BASIC.addrDtl,
            licAttchId: this.GET_BASIC.licAttchId,
            workType: this.GET_BASIC.workType,
            repType: this.GET_BASIC.repType,
            saupjaNo: this.GET_BASIC.saupjaNo,
            saupjaAttchId: this.GET_BASIC.saupjaAttchId,
            etcRepType: this.GET_BASIC.etcRepType,
            mktCollAgrYn: this.GET_BASIC.mktCollAgrYn,
            emailRcvAgrYn: this.GET_BASIC.emailRcvAgrYn,
            smsRcvAgrYn: this.GET_BASIC.smsRcvAgrYn,
            saupjaAttchNm: this.GET_BASIC.saupjaAttchNm,
            licAttchNm: this.GET_BASIC.licAttchNm,
            posX: this.GET_BASIC.posX,
            posY: this.GET_BASIC.posY,
          };
          this.form.doctorLicense = fetchedMemberInfo.licNo;
          this.form.id = fetchedMemberInfo.drId;
          this.form.name = fetchedMemberInfo.drNm;
          this.form.birth = fetchedMemberInfo.birth;
          if (fetchedMemberInfo.sex === "1") {
            this.form.gender = "1";
          } else if (fetchedMemberInfo.sex === "0") {
            this.form.gender = "0";
          }

          this.form.phone.value = this.phoneFomatter(fetchedMemberInfo.hpNo);
          if (
            fetchedMemberInfo.hpNo !== "" &&
            fetchedMemberInfo.hpNo !== null
          ) {
            this.form.phone.flag = true;
          }
          this.form.email.value = fetchedMemberInfo.email;
          if (
            fetchedMemberInfo.email !== "" &&
            fetchedMemberInfo.email !== null
          ) {
            this.form.email.flag = true;
          }
          this.form.hospital.value = fetchedMemberInfo.hospNm;
          if (
            fetchedMemberInfo.hospNm !== "" &&
            fetchedMemberInfo.hospNm !== null
          ) {
            this.form.hospital.flag = true;
          }
          this.form.address.zonecode.value = fetchedMemberInfo.postNo;
          if (
            fetchedMemberInfo.telNo !== "" &&
            fetchedMemberInfo.telNo !== null
          ) {
            this.form.hospitalTelNo.flag = true;
            this.form.hospitalTelNo.value = fetchedMemberInfo.telNo;
          }
          if (
            fetchedMemberInfo.postNo !== "" &&
            fetchedMemberInfo.postNo !== null
          ) {
            this.form.address.zonecode.flag = true;
          }
          //////////////////////////////////////////////////////////////////////////////////////
          this.form.address.locationPoint.posX = fetchedMemberInfo.posX;
          this.form.address.locationPoint.posY = fetchedMemberInfo.posY;
          ////////////////////////////////////////////////////////////////////////////////////////
          this.form.address.buildingname.value = fetchedMemberInfo.addr;
          if (
            fetchedMemberInfo.addr !== "" &&
            fetchedMemberInfo.addr !== null
          ) {
            this.form.address.buildingname.flag = true;
          }
          this.form.address.aim.value = fetchedMemberInfo.addr2;
          this.form.zipCodeDetail.value = fetchedMemberInfo.addrDtl;
          if (
            fetchedMemberInfo.addrDtl !== "" &&
            fetchedMemberInfo.addrDtl !== null
          ) {
            this.form.zipCodeDetail.flag = true;
          }
          this.form.licAttchId = fetchedMemberInfo.licAttchId;
          if (
            fetchedMemberInfo.licAttchId !== null &&
            fetchedMemberInfo.licAttchId !== ""
          ) {
            this.form.attachment[0].flag = true;
            this.form.attachment[0].filename = fetchedMemberInfo.licAttchNm;
          }
          this.form.saupjaAttchId = fetchedMemberInfo.saupjaAttchId;
          if (
            fetchedMemberInfo.saupjaAttchId !== null &&
            fetchedMemberInfo.saupjaAttchId !== ""
          ) {
            this.form.attachment[1].flag = true;
            this.form.attachment[1].filename = fetchedMemberInfo.saupjaAttchNm;
          }
          if (
            fetchedMemberInfo.saupjaNo !== "" &&
            fetchedMemberInfo.saupjaNo !== null
          ) {
            this.form.buisnessLicense.value = this.saupjaFomatter(
              fetchedMemberInfo.saupjaNo
            );
            this.form.buisnessLicense.flag = true;
          }
          this.form.employType.value = fetchedMemberInfo.workType;
          if (
            fetchedMemberInfo.workType !== "" &&
            fetchedMemberInfo.workType !== null
          ) {
            this.form.employType.flag = true;
          }
          this.form.delegate.value = fetchedMemberInfo.repType;
          if (
            fetchedMemberInfo.repType !== "" &&
            fetchedMemberInfo.repType !== null
          ) {
            this.form.delegate.flag = true;
          }
          this.form.delegateEtc.value = fetchedMemberInfo.etcRepType;
          if (
            fetchedMemberInfo.etcRepType !== "" &&
            fetchedMemberInfo.etcRepType !== null
          ) {
            this.form.delegateEtc.flag = true;
          }
          fetchedMemberInfo.emailRcvAgrYn === "Y" ||
          fetchedMemberInfo.smsRcvAgrYn === "Y"
            ? (this.form.mktCollAgrYn = true)
            : (this.form.mktCollAgrYn = false);

          fetchedMemberInfo.emailRcvAgrYn === "Y"
            ? (this.form.agreement[0].flag = true)
            : (this.form.agreement[0].flag = false);
          fetchedMemberInfo.smsRcvAgrYn === "Y"
            ? (this.form.agreement[1].flag = true)
            : (this.form.agreement[1].flag = false);
        });
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "입력정보가 서버로 전송되지 못했습니다.";
          this.popupSet.popType = "custom";
          this.popupSet.cancelBtnText = undefined;
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.nextLink = "/v/mypage/request/0";
          this.popupSet.destination = true;
        }
      }
      /** 비밀번호는 팝업창을 통해서 가져오기 (sesstionStorage) */
    },
    goPrev() {
      window.history.go(-1);
    },
    async getAddress(address) {
      let result;
      await this.FETCH_BASIC_ADDRESS(address.address).then((data) => {
        result = data.result;
        if (result === true) {
          this.form.address.locationPoint.posX = data.posX;
          this.form.address.locationPoint.posY = data.posY;
          this.loadMap = true;
        }
        /** API 통신해서 받아온 문자열 */
      });
      if (result === false) {
        this.SET_POPUP(true);
        this.popupSet.title = "";
        this.popupSet.content = "위치정보를 받아오지 못했습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        this.SET_ADDRESS_POPUP(false);
      }
      ////////////////////////////////////////////
      else {
        this.form.address.buildingname.flag = true;
        this.form.address.buildingname.hasError = false;
        this.form.address.buildingname.value =
          address.address + " " + address.buildingName;
        this.form.address.zonecode.value = address.zonecode;
        this.form.address.bname.value = address.bname;
        this.form.address.aim.value = address.sido + " " + address.sigungu;
        this.SET_ADDRESS_POPUP(false);
        if (document.querySelector("body").classList.contains("spreaded")) {
          document.querySelector("body").classList.remove("spreaded");
        }
      }
    },
    searchAddress() {
      this.SET_ADDRESS_POPUP(true);
      this.popupSet.popType = "address";
    },
    checkEmail() {
      this.form.email.flag = false;
      this.form.email.hasError = true;
      const email = this.form.email.value.replace(/\s/gi, "");
      if (email === "") {
        this.form.email.flag = false;
        this.form.email.hasError = true;
        this.form.email.errorTxt = "*이메일을 입력해주세요.";
        return false;
      }
      const isEmail =
        /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
      if (!isEmail.test(email)) {
        this.form.email.flag = false;
        this.form.email.hasError = true;
        this.form.email.errorTxt = "*이메일 형식에 맞지 않습니다.";
        return false;
      }
      this.form.email.flag = true;
      this.form.email.hasError = false;
      this.form.email.errorTxt = "";
      return true;
    },
    checkHospital() {
      this.form.hospital.flag = false;
      this.form.hospital.hasError = true;
      const hospital = this.form.hospital.value.replace(/\s/gi, "");
      if (hospital === "") {
        this.form.hospital.flag = false;
        this.form.hospital.errorTxt = "*병원명을 입력해주세요.";
        return false;
      }
      const isHan = /^[ㄱ-ㅎ가-힣|a-z|A-Z|0-9]{2,40}$/;
      if (!isHan.test(hospital)) {
        this.form.hospital.errorTxt = "*한글,영문,숫자만 입력할 수 있습니다.";
        this.form.hospital.flag = false;
        return false;
      }
      this.form.hospital.flag = true;
      this.form.hospital.hasError = false;
      this.form.hospital.errorTxt = "";
      return true;
    },
    checkHospitalTelNo() {
      this.form.hospitalTelNo.flag = false;
      this.form.hospitalTelNo.hasError = true;
      const hospitalTelNo = this.form.hospitalTelNo.value.replace(/\s/gi, "");
      if (hospitalTelNo === "") {
        this.form.hospitalTelNo.flag = false;
        this.form.hospitalTelNo.errorTxt =
          "*병원 전화번호를 '-'를 포함하여 형식에 맞게 입력해주세요.";
        return false;
      }

      const isHan = /^\d{2,3}-\d{3,4}-\d{4}$/;
      if (hospitalTelNo === "1522-8775") {
      } else if (!isHan.test(hospitalTelNo)) {
        this.form.hospitalTelNo.errorTxt =
          "*병원 전화번호를 '-'를 포함하여 형식에 맞게 입력해주세요.";
        this.form.hospitalTelNo.flag = false;
        return false;
      }
      this.form.hospitalTelNo.flag = true;
      this.form.hospitalTelNo.hasError = false;
      this.form.hospitalTelNo.errorTxt = "";
      return true;
    },
    checkFlag() {
      if (
        !this.form.phone.flag ||
        !this.form.email.flag ||
        !this.form.hospital.flag ||
        !this.form.employType.flag ||
        /*!this.form.delegate.flag ||*/
        !this.form.address.buildingname.flag ||
        !this.form.hospitalTelNo.flag
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "입력값 확인";
        this.popupSet.content =
          "기입되지 않았거나 잘못 기입된 항목이 있습니다. 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";

        if (!this.form.phone.flag) {
          this.form.phone.hasError = true;
        }
        if (!this.form.email.flag) {
          this.form.email.hasError = true;
        }
        if (!this.form.hospital.flag) {
          this.form.hospital.hasError = true;
        }
        if (!this.form.employType.flag) {
          this.form.employType.hasError = true;
        }
        if (!this.form.delegate.flag) {
          this.form.delegate.hasError = true;
        }
        if (!this.form.address.buildingname.flag) {
          this.form.address.buildingname.hasError = true;
        }
        if (!this.form.hospitalTelNo.flag) {
          this.form.hospitalTelNo.hasError = true;
        }

        return false;
      }
      if (this.form.delegate.value === "2") {
        if (!this.form.delegateEtc.flag) {
          this.form.delegateEtc.hasError = true;
          return false;
        }
      }
      return true;
    },
    checkZipCodeDetail() {
      this.form.zipCodeDetail.flag = false;
      const zipCodeDetail = this.form.zipCodeDetail.value.replace(/\s/gi, "");
      if (zipCodeDetail === "") {
        this.form.zipCodeDetail.flag = false;
        return false;
      }
      this.form.zipCodeDetail.flag = true;
      return true;
    },
    async next() {
      if (this.checkFlag()) {
        const basicParam = {
          licNo: this.form.doctorLicense,
          drId: this.form.id,
          drNm: this.form.name,
          birth: this.form.birth,
          sex: this.form.gender,
          hpNo: this.form.phone.value.replace(/-/gi, ""),
          email: this.form.email.value,
          hospNm: this.form.hospital.value,
          telNo: this.form.hospitalTelNo.value,
          postNo: this.form.address.zonecode.value,
          addr: this.form.address.buildingname.value,
          addr2: this.form.address.aim.value,
          addrDtl: this.form.zipCodeDetail.value,
          workType: this.form.employType.value,
          repType: this.form.delegate.value,
          licAttchId: this.form.licAttchId,
          saupjaAttchId: this.form.saupjaAttchId,
          mktCollAgrYn: this.form.mktCollAgrYn ? "Y" : "N",
          emailRcvAgrYn: this.form.agreement[0].flag ? "Y" : "N",
          smsRcvAgrYn: this.form.agreement[1].flag ? "Y" : "N",
        };
        if (this.form.delegate.value === "2") {
          basicParam.etcRepType = this.form.delegateEtc.value;
          basicParam.isDeletedSaupjaAttchId = 1;
        } else {
          basicParam.isDeletedSaupjaAttchId = 1;
          basicParam.etcRepType = null;
        }
        const transData = new FormData();
        transData.append(
          "licFile",
          this.form.attachment[0].source === null
            ? this.GET_BASIC.licAttchId
            : this.form.attachment[0].source
        );
        if (this.form.attachment[0].source !== null) {
          basicParam.isDeletedLicAttchId = 0;
        }
        transData.append(
          "saupjaFile",
          this.form.attachment[1].source === null
            ? this.GET_BASIC.saupjaAttchId
            : this.form.attachment[1].source
        );
        if (this.form.attachment[1].source !== null) {
          basicParam.isDeletedSaupjaAttchId = 0;
        }
        if (this.form.attachment[1].isDeleted === 1) {
          basicParam.isDeletedSaupjaAttchId = 1;
        }
        //console.log("서버로 전송할 베이직", basicParam);

        // 1. 의사면허번호
        if (basicParam.licNo === null || basicParam.licNo === undefined)
          basicParam.licNo = "";

        var licNoBoo = /^[0-9]{0,6}$/;

        if (!licNoBoo.test(basicParam.licNo)) {
          this.SET_POPUP(true);
          this.popupSet.title = "의사면허번호 확인";
          this.popupSet.content =
            "의사면허번호가 숫자 1~6자 이하인지 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 2. 아이디
        if (basicParam.drId === null || basicParam.drId === undefined)
          basicParam.drId = "";

        var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

        if (!drIdBoo.test(basicParam.drId)) {
          this.SET_POPUP(true);
          this.popupSet.title = "로그인 확인";
          this.popupSet.content =
            "로그인이 정상적이지 않습니다. \n 로그아웃 및 재로그인을 해주시기 바랍니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 3. 이름
        if (basicParam.drNm === null || basicParam.drNm === undefined)
          basicParam.drNm = "";

        var drNmBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{1,100}$/;

        if (!drNmBoo.test(basicParam.drNm.replace(/-/gi, ""))) {
          this.SET_POPUP(true);
          this.popupSet.title = "이름 확인";
          this.popupSet.content =
            "이름이 정상적이지 않습니다. \n 로그아웃 및 재로그인을 해주시기 바랍니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 4. 생년월일
        if (basicParam.birth === null || basicParam.birth === undefined)
          basicParam.birth = "";

        var birthBoo = /^[0-9]{8,8}$/;

        if (!birthBoo.test(basicParam.birth)) {
          this.SET_POPUP(true);
          this.popupSet.title = "생년월일 확인";
          this.popupSet.content =
            "생년월일이 정상적이지 않습니다. \n 로그아웃 및 재로그인을 해주시기 바랍니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 5. 성별
        if (basicParam.sex === null || basicParam.sex === undefined)
          basicParam.sex = "";

        var sexBoo = /^[0-9]{0,1}$/;

        if (!sexBoo.test(basicParam.sex)) {
          this.SET_POPUP(true);
          this.popupSet.title = "성별 확인";
          this.popupSet.content =
            "성별이 정상적이지 않습니다. \n 로그아웃 및 재로그인을 해주시기 바랍니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 6. 휴대폰번호
        if (basicParam.hpNo === null || basicParam.hpNo === undefined)
          basicParam.hpNo = "";

        var hpNoBoo = /^[0-9]{0,14}$/;

        if (!hpNoBoo.test(basicParam.hpNo)) {
          this.SET_POPUP(true);
          this.popupSet.title = "휴대폰번호 확인";
          this.popupSet.content = "휴대폰번호의 숫자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 7. 이메일
        if (basicParam.email === null || basicParam.email === undefined)
          basicParam.email = "";

        var emailBoo = /^[a-z0-9A-Z._-]*@[a-z0-9A-Z]*.[a-zA-Z.]*$/;

        if (basicParam.email.length !== 0) {
          if (!emailBoo.test(basicParam.email)) {
            this.SET_POPUP(true);
            this.popupSet.title = "이메일 확인";
            this.popupSet.content = "이메일을 확인해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }
        } else {
          this.SET_POPUP(true);
          this.popupSet.title = "이메일 확인";
          this.popupSet.content = "이메일을 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 8. 병원명
        if (basicParam.hospNm === null || basicParam.hospNm === undefined)
          basicParam.hospNm = "";

        if (basicParam.hospNm < 0 || basicParam.hospNm > 100) {
          this.SET_POPUP(true);
          this.popupSet.title = "병원명 확인";
          this.popupSet.content =
            "병원명의 글자 수 및 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 9. 병원 전화번호
        if (basicParam.telNo === null || basicParam.telNo === undefined)
          basicParam.telNo = "";

        var telNoBoo = /^[0-9]{0,14}$/;

        if (!telNoBoo.test(basicParam.telNo.replace(/-/gi, ""))) {
          this.SET_POPUP(true);
          this.popupSet.title = "병원 전화번호 확인";
          this.popupSet.content = "병원 전화번호의 숫자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 10. 우편번호
        if (basicParam.postNo === null || basicParam.postNo === undefined)
          basicParam.postNo = "";

        var postNoBoo = /^[0-9]{1,6}$/;

        if (!postNoBoo.test(basicParam.postNo)) {
          this.SET_POPUP(true);
          this.popupSet.title = "우편번호 확인";
          this.popupSet.content = "우편번호의 숫자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 11. 주소
        if (basicParam.addr === null || basicParam.addr === undefined)
          basicParam.addr = "";

        if (basicParam.addr < 1 || basicParam.addr > 200) {
          this.SET_POPUP(true);
          this.popupSet.title = "주소 확인";
          this.popupSet.content = "주소를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 12. 상세주소
        if (basicParam.addrDtl === null || basicParam.addrDtl === undefined)
          basicParam.addrDtl = "";

        if (basicParam.addrDtl < 0 || basicParam.addrDtl > 200) {
          this.SET_POPUP(true);
          this.popupSet.title = "상세주소 확인";
          this.popupSet.content = "상세주소를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 13. 지번주소
        if (basicParam.addr2 === null || basicParam.addr2 === undefined)
          basicParam.addr2 = "";

        if (basicParam.addr2 < 0 || basicParam.addr2 > 50) {
          this.SET_POPUP(true);
          this.popupSet.title = "지번주소 확인";
          this.popupSet.content = "지번주소를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 14. 근무형태
        if (basicParam.workType === null || basicParam.workType === undefined)
          basicParam.workType = "";

        var workTypeBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{1,20}$/;

        if (!workTypeBoo.test(basicParam.workType)) {
          this.SET_POPUP(true);
          this.popupSet.title = "근무형태 확인";
          this.popupSet.content = "근무형태를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 15. 고용형태
        if (basicParam.repType === null || basicParam.repType === undefined)
          basicParam.repType = "";

        var repTypeBoo = /^[0-9]{1,1}$/;

        if (!repTypeBoo.test(basicParam.repType)) {
          this.SET_POPUP(true);
          this.popupSet.title = "고용형태 확인";
          this.popupSet.content = "고용형태를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 16. 의사면허등록사진
        if (
          basicParam.licAttchId === null ||
          basicParam.licAttchId === undefined
        )
          basicParam.licAttchId = "";

        if (basicParam.licAttchId < 0 || basicParam.licAttchId > 40) {
          this.SET_POPUP(true);
          this.popupSet.title = "의사면허등록사진 확인";
          this.popupSet.content = "의사면허등록사진 오류";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 17. 사업자등록첨부파일
        if (
          basicParam.saupjaAttchId === null ||
          basicParam.saupjaAttchId === undefined
        )
          basicParam.saupjaAttchId = "";

        if (basicParam.saupjaAttchId < 0 || basicParam.saupjaAttchId > 40) {
          this.SET_POPUP(true);
          this.popupSet.title = "사업자등록첨부파일 확인";
          this.popupSet.content = "사업자등록첨부파일 오류";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 18. 마케팅정보수신동의여부
        if (
          basicParam.mktCollAgrYn === null ||
          basicParam.mktCollAgrYn === undefined
        )
          basicParam.mktCollAgrYn = "";

        var mktCollAgrYnBoo = /^[A-Z]{1,1}$/;

        if (!mktCollAgrYnBoo.test(basicParam.mktCollAgrYn)) {
          this.SET_POPUP(true);
          this.popupSet.title = "마케팅정보수신동의여부 확인";
          this.popupSet.content = "마케팅정보수신동의여부가 확인되지 않습니다";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 19. EMAIL수신동의여부
        if (
          basicParam.emailRcvAgrYn === null ||
          basicParam.emailRcvAgrYn === undefined
        )
          basicParam.emailRcvAgrYn = "";

        var emailRcvAgrYnBoo = /^[A-Z]{1,1}$/;

        if (!emailRcvAgrYnBoo.test(basicParam.emailRcvAgrYn)) {
          this.SET_POPUP(true);
          this.popupSet.title = "EMAIL수신동의여부 확인";
          this.popupSet.content = "EMAIL수신동의여부 오류";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 20. SMS수신동의여부
        if (
          basicParam.smsRcvAgrYn === null ||
          basicParam.smsRcvAgrYn === undefined
        )
          basicParam.smsRcvAgrYn = "";

        var smsRcvAgrYnBoo = /^[A-Z]{1,1}$/;

        if (!smsRcvAgrYnBoo.test(basicParam.smsRcvAgrYn)) {
          this.SET_POPUP(true);
          this.popupSet.title = "SMS수신동의여부 확인";
          this.popupSet.content = "SMS수신동의여부 오류";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        const transedBasicParam = JSON.stringify(basicParam);
        transData.append("basicParam", transedBasicParam);
        try {
          await this.MODIFY_BASIC(transData).then(() => {
            this.SET_POPUP(true);
            this.popupSet.title = "기본정보 변경성공";
            this.popupSet.content = "기본정보가 성공적으로 등록되었습니다.";
            this.popupSet.popType = "custom";
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.cancelBtnText = undefined;
            this.popupSet.nextLink = "/v/mypage/request/0";
            this.popupSet.destination = true;

            this.$emit("sidebar-update-method");
          });
        } catch (error) {
          console.log("error:", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "입력정보가 서버로 전송되지 못했습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "입력값 확인";
        this.popupSet.content = "입력값을 확인해주세요";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
  },
  destroyed() {
    this.SET_POPUP(false);
  },
};
</script>

<style lang="scss">
@import "@/assets/css/join";
@import "@/assets/css/sideContext";
@import "@/assets/scss/ModifyMember";
input,
textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
.custom-table {
  background-color: #f5f9fa;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}
.custom-table-cell {
  align-items: center;
  display: flex;
}
.custom-table-row {
  border-bottom: 1px solid lightgrey;
}
.background-cell {
  background-color: white;
  min-height: 80px;
}
</style>

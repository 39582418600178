<template>
  <div>
    <naver-maps
      :height="propsHeight === undefined ? height : propsHeight"
      :width="propsWidth === undefined ? width : propsWidth"
      :mapOptions="mapOptions"
      :initLayers="initLayers"
      @load="onLoad"
    >
    </naver-maps>
    <naver-marker
      :lat="posY"
      :lng="posX"
      @click="onMarkerClicked"
      @load="onMarkerLoaded"
    />
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    posX: Number,
    posY: Number,
    address: String,
    propsWidth: Number,
    propsHeight: Number,
  },
  data() {
    return {
      width: 800,
      height: 800,
      info: false,
      marker: null,
      count: 1,
      map: null,
      isCTT: false,
      mapOptions: {
        lat: this.posY,
        lng: this.posX,
        zoom: 15,
        zoomControl: false,
        zoomControlOptions: { position: "TOP_RIGHT" },
        mapTypeControl: true,
        center: new naver.maps.LatLng(this.posY, this.posX),
      },
      initLayers: [
        "BACKGROUND",
        "BACKGROUND_DETAIL",
        "POI_KOREAN",
        "TRANSIT",
        "ENGLISH",
        "CHINESE",
        "JAPANESE",
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      var Size = new naver.maps.Size(this.propsWidth, this.propsHeight);
      this.map.setSize(Size);
    },
    onLoad(vue) {
      this.map = vue;
    },
    onMarkerClicked(event) {
      this.info = !this.info;
    },
    onMarkerLoaded(vue) {
      this.marker = vue.marker;
    },
  },
  mounted() {},
};
</script>
<style scoped></style>
